import * as React from "react"
import styled from "styled-components"


export const MobileNavButton = props => {
  const NavSpan = styled.span`
    display: none;
    @media only screen and (max-width: ${props.breakpoint}) {
      display: unset;
    }
  `
  return (
    <NavSpan>
      <svg
        fill={props.fill}
        viewBox="0 0 100 80"
        width={props.width}
        height={props.height}
      >
        <rect width="100" height="20"></rect>
        <rect y="30" width="100" height="20"></rect>
        <rect y="60" width="100" height="20"></rect>
      </svg>
    </NavSpan>
  )
}
