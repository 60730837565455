import * as React from "react"

export const Facebook = props => {
  return (
    <span>
      <svg
        id="Bold"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={props.fill}
        width={props.width}
        height={props.height}
      >
        <title>facebook-icon</title>
        <path d="M19.55,14.56a.5.5,0,0,1-.5.44H17a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h6a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1V23a1,1,0,0,0,1,1H12a.5.5,0,0,0,.5-.5v-8A.5.5,0,0,0,12,15H10a.5.5,0,0,1-.5-.5v-3A.5.5,0,0,1,10,11h2a.5.5,0,0,0,.5-.5V9.19A5.69,5.69,0,0,1,18.19,3.5H19.5A.5.5,0,0,1,20,4V7a.5.5,0,0,1-.5.5H18.19A1.69,1.69,0,0,0,16.5,9.19V10.5a.5.5,0,0,0,.5.5h2.43a.5.5,0,0,1,.5.56Z" />
      </svg>
    </span>
  )
}
