import { useState } from "react"
import * as React from "react"
import styled from "styled-components"

import { fontPrimary, size, trumanColors } from "../styles/styles"
import { MobileNavButton } from "./Icons/MobileNav"
import { SocialIcons } from "./SocialIcons"

export const NavBar = () => {
  const { bluePrim, blueSecond, offwhite } = trumanColors
  const [isOpen, setIsOpen] = useState(true)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const HamburgerButton = styled.button`
    background: transparent;
    border: none;
  `

  const NavItemWrapper = styled.div`
    display: unset;
    font-size: 2em;
    font-family: ${fontPrimary};
    margin-right: auto;
    margin-left: auto;
    a,
    a:visited {
      color: ${offwhite};
      text-decoration: none;
      margin-left: 4vw;
      margin-right: 4vw;
    }
    a:hover,
    a:active {
      color: ${blueSecond};
    }

    @media only screen and (max-width: ${size.mobile}) {
      display: none;
    }
  `

  const MobileItemWrapper = styled(NavItemWrapper)`
    display: none;
    border-top: 1px solid ${offwhite};
    text-align: center;
    width: 100vw;
    background-color: ${bluePrim};
    a {
      z-index: 1000;
      display: block;
      background-color: ${bluePrim};
      width: 100%;
    }
    a,
    a:visited {
      color: ${offwhite};
      text-decoration: none;
      margin-left: 0;
      margin-right: 0;
    }
    a:hover,
    a:active {
      color: ${blueSecond};
    }
    @media only screen and (max-width: ${size.mobile}) {
      display: unset;
    }
  `

  const NavContainer = styled.div`
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: flex-end;
    height: 13vh;
    padding: 0 10px 10px;
    background-color: ${bluePrim};
}
  `

  return (
    <>
      <NavContainer>
        <HamburgerButton onClick={toggleMenu}>
          <MobileNavButton
            fill={offwhite}
            height={"40px"}
            width={"40px"}
            breakpoint={size.mobile}
          />
        </HamburgerButton>
        <NavItemWrapper>
          <a href="/">
            <span>Home</span>
          </a>
          {/*<a href="/events">*/}
          <a href="/">
            <span>Events</span>
          </a>
          {/*<a href="/donate">*/}
          <a href="/">
            <span>Donate</span>
          </a>
          {/*<a href="/shop">*/}
          <a href="/">
            <span>Shop</span>
          </a>
        </NavItemWrapper>
        <SocialIcons />
      </NavContainer>
      {isOpen ? (
        <></>
      ) : (
        <MobileItemWrapper>
          <a href="/">
            <span>Home</span>
          </a>
          {/*<a href="/events">*/}
          <a href="/">
            <span>Events</span>
          </a>
          {/*<a href="/donate">*/}
          <a href="/">
            <span>Donate</span>
          </a>
          {/*<a href="/shop">*/}
          <a href="/">
            <span>Shop</span>
          </a>
        </MobileItemWrapper>
      )}
    </>
  )
}
