import * as React from "react"
import styled from "styled-components"
import { iconSize, trumanColors } from "../styles/styles"
import { Facebook } from "./Icons/Facebook"
import { Instagram } from "./Icons/Instagram"

export const SocialIcons = props => {
  const { offwhite } = trumanColors

  const IconContainer = styled.div``
  const IconWrapper = styled.span`
    margin: 0 10px;
  `
  return (
    <IconContainer>
      <IconWrapper>
        <a href="https://www.facebook.com/trumanthecamel/" target="_blank">
          <Facebook width={iconSize} height={iconSize} fill={offwhite} />
        </a>
      </IconWrapper>
      <IconWrapper>
        <a
          href="https://instagram.com/trumanforpresident?igshid=1caklya5ayyr8"
          target="_blank"
        >
          <Instagram width={iconSize} height={iconSize} fill={offwhite} />
        </a>
      </IconWrapper>
    </IconContainer>
  )
}
