export const trumanColors: any = {
  red: "#EC1C24",
  grey: "#797D81",
  black: "#0C0F0A",
  white: "#FDFFFC",
  offwhite: "#FDF0D5",
  bluePrim: "#425C8B",
  blueSecond: "#1098F7",
}

export const iconSize = "40px"

export const fontPrimary: string = `font-family: 'Open Sans', sans-serif;`

export const size = {
  mobile: "800px",
  small: "1000px",
  medium: "1200px",
}
